
        @import "./styles/matcha.scss";
      
@keyframes clippath {
  0%,
  100% {
    clip-path: inset(0 0 98% 0);
  }

  25% {
    clip-path: inset(0 98% 0 0);
  }
  50% {
    clip-path: inset(98% 0 0 0);
  }
  75% {
    clip-path: inset(0 0 0 98%);
  }
}

.container {
  display: flex;
  gap: 12px;
  border-radius: 8px;
  font-size: 24px;
  text-align: center;
  position: relative;
  width: fit-content;

  .input {
    width: 44px;
    height: 44px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 8px;
    border: 1px solid #cdd5e51a;
    background: transparent;
    font-size: 24px;
    font-weight: 600;
    line-height: 10px;
  }

  .input:focus {
    outline: 0;
    box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.1);
    border-color: #cdd5e54d;
  }

  @media only screen and (max-width: 460px) {
    gap: 8px;

    .input {
      width: 40px;
      height: 42px;
      font-size: 22px;
    }
  }
}

.error {
  border: 2px solid theme('colors.border-color-critical-subdued') !important;
}

.caption {
  height: 0;
  opacity: 0;
  transition: all 0.1s;
}

.show {
  height: 16px;
  opacity: 1;
}
