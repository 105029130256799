
        @import "./styles/matcha.scss";
      
@import '~@axieinfinity/matcha/dist/styles/scss/root';
@import '~@axieinfinity/matcha/dist/styles/scss/reboot';
@import '~@axieinfinity/matcha/dist/matcha.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans antialiased;
}

@keyframes three-dots {
  0%,
  20% {
    color: transparent;
    text-shadow: 0.25em 0 0 transparent, 0.5em 0 0 transparent;
  }
  40% {
    color: var(--dg-color-white-1);
    text-shadow: 0.25em 0 0 transparent, 0.5em 0 0 transparent;
  }
  60% {
    text-shadow: 0.25em 0 0 var(--dg-color-white-1), 0.5em 0 0 transparent;
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 var(--dg-color-white-1),
      0.5em 0 0 var(--dg-color-white-1);
  }
}

:root {
  --max-screen-width: 1440px;
  --max-content-width: 936px;
  --max-header-width: 1224px;
  --header-height: 64px;

  @media print {
    body {
      * {
        -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
        color-adjust: exact !important; /* Firefox 48 – 96 */
        print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
      }
    }
  }
}

@layer components {
  .matcha-overlay {
    border-radius: 12px;
    background: rgba(12, 12, 13, 0.8);
  }

  .matcha-interaction-inner-shadow {
    box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.2) inset,
      0px 0px 30px 0px rgba(255, 255, 255, 0.02) inset;
    backdrop-filter: blur(9px);
  }

  .matcha-background-blur {
    backdrop-filter: blur(12.5px);
  }

  .matcha-focus {
    box-shadow: 0px 0px 0px 2px #0c48c0;
  }

  .matcha-active {
    box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.1);
  }

  .matcha-divider-top {
    box-shadow: 0px 1px 0px 0px #272b34 inset;
  }

  .matcha-divider-bottom {
    box-shadow: 0px -1px 0px 0px #272b34 inset;
  }

  .matcha-divider-right {
    box-shadow: -1px 0px 0px 0px #272b34 inset;
  }

  .matcha-scroll-container {
    @apply overflow-y-auto;
    height: calc(100dvh - var(--header-height));
  }

  .matcha-content-wrapper {
    min-height: calc(100dvh - var(--header-height) - var(--footer-height));
  }

  .matcha-content-inner {
    @apply mx-auto py-48 px-16;
    max-width: var(--max-content-width);
  }

  .matcha-shadow-scroll {
    position: relative;

    &:before {
      @apply z-10;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      width: 50px;
      position: absolute;
      background: linear-gradient(90deg, rgba(13, 14, 18, 0.5), transparent);
    }

    &:after {
      @apply z-10;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      width: 50px;
      position: absolute;
      background: linear-gradient(90deg, rgba(13, 14, 18, 0.5), transparent);
      transform: scaleX(-1);
    }
  }
}

.three-dots-loading:after {
  content: '.';
  animation: three-dots 2s steps(5, end) infinite;
}

// css matcha-toast top-center & zIndex > matcha-dialog (1050)
.matcha-toast {
  z-index: 1060;
  width: 100%;
  display: block !important;

  @media only screen and (min-width: 640px) {
    max-width: 532px;
    left: 50%;
    transform: translateX(-50%);
  }
}

div[class^='toast-module_contentWrapper'] {
  gap: 0 !important;
}

div[class^='alert-module_alert'] {
  & > div {
    overflow: auto;
    overflow-wrap: anywhere;
  }
}

// OVERRIDE CSS CAPTCHA

.axie-captcha-dialog {
  @media only screen and (max-width: 768px) {
    padding: 16px;
  }
}
