
        @import "./styles/matcha.scss";
      
.container {
  max-width: 448px;
  width: 100%;
}

.image {
  transform: translateY(13px);
  margin: 0 auto;
  user-select: none;
}

.box {
  border: 1px solid
    var(--interaction-border-secondary-hover, rgba(205, 213, 229, 0.3));
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.1);
  border-radius: var(--mc-radius-rounded-3x);
}
